<template>

<div class="ticket" :class="{'is-used': item.scanned}" v-tooltip="(item.event) ? 'Click to view event' : 'Click to view applicable events'" v-on:click="onClick">

	<div class="ticket-main">

		<div class="ticket-main-name"><span>{{ item.ticket.name }}</span></div>

		<div class="ticket-main-info">
		
			<div class="ticket-main-info-item" v-if="item.cost">{{ item.cost | formatMoney }}</div>
			<div class="ticket-main-info-item" v-if="!item.cost">Free</div>
			<div class="ticket-main-info-item" v-if="item.event">{{ item.event.date | formatDate('dddd') }}</div>
			<div class="ticket-main-info-item" v-if="item.event">{{ item.event.date | formatDate('HH:mm') }}</div>
			<div class="ticket-main-info-item" v-if="!item.event">Not used</div>
		
		</div>

		<div class="ticket-main-text" v-if="item.ticket.note">
			{{ item.ticket.note }}
		</div>

		<div class="ticket-divide"></div>

	</div>

	<div class="ticket-stub">

		<div class="ticket-divide"></div>

		<div class="ticket-stub-top">Admit one</div>

		<div class="ticket-stub-code">
			<div v-for="(size, index) in bars" :key="index" :style="{height: size.toString() + 'px'}" />
		</div>

		<div class="ticket-stub-bottom">Admit one</div>

	</div>

</div>

</template>

<script>

export default {

	props: ['item'],

	data: function() {
		
		return {
			bars: [1,1,1,1,2,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4]
		}
	},
	
	created: function() {

		this.bars = this.$_.shuffle(this.bars)

	},

	methods: {

		onClick: function() {

			if (this.item.event) {

				this.$router.push({
					name: 'Convention.Schedule.Event',
					params: {
						id: this.item.event.id
					}
				})

			} else {

				this.$router.push({
					name: 'Convention.Schedule.Browse',
					query: {
						ticket: this.item.ticket.id
					}
				})

			}

		}

	}

}

</script>

<style scoped>

.ticket {
	width: 100%;
	height: 144px;
	display: flex;
	color: #204444;
	cursor: pointer;
	transition: transform 100ms linear;
}

.is-desktop .ticket:hover {
	transform: scale(1.05);
}

.ticket-main {
	flex-grow: 1;
	height: 144px;
	z-index: 2;
	padding: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: #b9f4f3;
	clip-path: polygon(
		100% 0%, 
		6px 0px, 
		0px 6px, 
		6px 12px, 
		0px 18px, 
		6px 24px, 
		0px 30px, 
		6px 36px, 
		0px 42px, 
		6px 48px, 
		0px 54px, 
		6px 60px, 
		0px 66px, 
		6px 72px, 
		0px 78px, 
		6px 84px, 
		0px 90px, 
		6px 96px,
		0px 102px, 
		6px 108px,
		0px 114px, 
		6px 120px,
		0px 126px, 
		6px 132px,
		0px 138px, 
		6px 144px,
		100% 100%
	);
}

.ticket-main-name {
	text-align: center;
	width: 100%;
	border-top: 2px solid #204444;
	padding: 10px 0px;
	border-bottom: 2px solid #204444;
	font-size: 18px;
	font-family: courier;
	text-transform: uppercase;
}

.ticket-main-info {
	display: flex;
	padding-top: 10px;
	align-items: center;
	z-index: 2;
}

.ticket-main-info-item {
	padding: 5px 0px;
	border-right: 1px solid #204444;
	font-size: 13px;
	font-weight: 400;
	flex-grow: 1;
	text-transform: uppercase;
	flex-shrink: 0;
	text-align: center;
}

.ticket-main-info-item:first-child {
	padding-left: 0px;
}

.ticket-main-info-item:last-child {
	padding-right: 0px;
	border-right: 0px;
}

.ticket-main-text {
	font-size: 10px;
	line-height: 12px;
	color: #204444;
	opacity: 0.75;
	padding-top: 10px;
	text-align: center;
}

.ticket-divide {
	display: block;
	content: '';
	position: absolute;
	right: 0px;
	top: 0px;
	height: 144px;
	width: 2px;
	background-color: transparent;
	border-right: 4px dotted #f2f7ff;
	z-index: 2;
}

.ticket-stub .ticket-divide {
	display: none;
}

.ticket.is-used .ticket-divide {
	right: -2px;
}

.ticket.is-used .ticket-stub .ticket-divide {
	left: -2px;
	right: auto;
	display: block;
}


.ticket-stub {
	flex-shrink: 0;
	width: 90px;
	height: 144px;
	z-index: 1;
	overflow: hidden;
	background-color: #b9f4f3;
	clip-path: polygon(
		0% 0%, 
		calc(100% - 6px) 0px, 
		100% 6px, 
		calc(100% - 6px) 12px, 
		100% 18px, 
		calc(100% - 6px) 24px, 
		100% 30px, 
		calc(100% - 6px) 36px, 
		100% 42px, 
		calc(100% - 6px) 48px, 
		100% 54px, 
		calc(100% - 6px) 60px, 
		100% 66px, 
		calc(100% - 6px) 72px, 
		100% 78px, 
		calc(100% - 6px) 84px, 
		100% 90px, 
		calc(100% - 6px) 96px,
		100% 102px, 
		calc(100% - 6px) 108px,
		100% 114px, 
		calc(100% - 6px) 120px,
		100% 126px, 
		calc(100% - 6px) 132px,
		100% 138px, 
		calc(100% - 6px) 144px,
		0% 100%
	);
}

.ticket-stub-top {
	position: absolute;
	text-transform: uppercase;
	color: #204444;
	opacity: 0.5;
	padding: 10px;
	text-align: center;
	top: 0px;
	left: 0px;
	font-size: 12px;
	font-family: courier;
}

.ticket-stub-code {
	position: absolute;
	top: 50%;
	margin-top: -38px;
	left: 10px;
	width: 64px;
	height: 76px;
}

.ticket-stub-code div:nth-child(odd) {
	background-color: #204444;
}

.ticket-stub-bottom {
	position: absolute;
	text-transform: uppercase;
	bottom: 0px;
	left: 0px;
	color: #204444;
	opacity: 0.5;
	padding: 10px;
	text-align: center;
	font-size: 12px;
	font-family: courier;
	transform: scale(-1, -1);
}

.ticket.is-used .ticket-stub {
	transform: rotate(10deg) translateX(-5px);
}

</style>